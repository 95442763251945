/* eslint-disable max-len */
// export const API_URI = 'https://aiapicore.com/api/ChatResults';
export const API_URI = 'https://aiapicore.com/api/ChatQAByToken';
export const QA_API_URI = 'https://aiapicore.com/api/ChatHistoryByQuestionGUID';
// export const API_URI = 'https://chatwmo-app.azurewebsites.net/api/energy';

export const resources = [
  // {
  //   name: 'IPCC AR6 WGs 2022',
  //   key: 'IPCC',
  // },
  // {
  //   name: 'IPCC AR6 Synthesis Report 2023',
  //   key: 'IPCC-SYR',
  // },
  // {
  //   name: 'WMO State of Climate Reports',
  //   key: 'WMO',
  // },
  {
    name: 'WMO Regional Association Reports',
    key: 'WMO_Regional_Association_Reports_new3',
    group: 'WMO Governance and Policies',
  },
  {
    name: 'WMO Basic Documents',
    key: 'WMO_basic_new',
    group: 'WMO Governance and Policies',
  },
  {
    name: 'WMO Rules of Procedure',
    key: 'WMO Rulles of procedure session reports_new',
    group: 'WMO Governance and Policies',
  },
  {
    name: 'WMO Cg',
    key: 'WMO_cg_new3',
    group: 'WMO Governance and Policies',
  },
  {
    name: 'WMO EC',
    key: 'WMO_ec_new3',
    group: 'WMO Governance and Policies',
  },
  {
    name: 'WMO Strategic Plan',
    key: 'WMO_Strategic_Plan_new',
    group: 'WMO Governance and Policies',
  },
  {
    name: 'WMO Technical Regulations',
    key: 'WMO_Technical_Regulation_new',
    group: 'Technical and Scientific Documents',
  },
  {
    name: 'WMO Manuals-Annexes to TRs',
    key: 'WMO_Manuals_Annexes_TRs_new',
    group: 'Technical and Scientific Documents',
  },
  {
    name: 'WMO Guides',
    key: 'WMO-Guides_new',
    group: 'Technical and Scientific Documents',
  },
  {
    name: 'WMO INFCOMs',
    key: 'WMO_INFCOMs_new3',
    group: 'Technical and Scientific Documents',
  },
  {
    name: 'WMO SERCOMs',
    key: 'WMO_SERCOMs_new3',
    group: 'Technical and Scientific Documents',
  },
  {
    name: 'United in Science',
    key: 'WMO_united_science_new_1',
    group: 'Climate and Science Reports',
  },
  {
    name: 'Climate Statements',
    key: 'WMO_Climate_Statement_new',
    group: 'Climate and Science Reports',
  },
  {
    name: 'State of Climate Services',
    key: 'WMO_State_Climate_Services_new3',
    group: 'Climate and Science Reports',
  },
  {
    name: 'World Weather Research Program',
    key: 'WMO_WWRP_new3',
    group: 'Climate and Science Reports',
  },
  {
    name: 'Global Greenhouse Gas Watch Website',
    key: 'wmo-g3-web3',
    group: 'Digital and Online Resources',
  },
  {
    name: 'WMO LinkedIn',
    key: 'wmo-linkedin',
    group: 'Digital and Online Resources',
  },
];

export const defaultResources = [
  // {
  //   name: 'IPCC AR6 WGs 2022',
  //   key: 'IPCC',
  // },
  // {
  //   name: 'WMO Basic Documents',
  //   key: 'WMO_basic_new',
  // },
  {
    name: 'WMO EC',
    key: 'WMO_ec_new3',
  },
  {
    name: 'WMO Guides',
    key: 'WMO-Guides_new',
  },
];

export const wmoNumbersArray = [
  1353, 1352, 1266, 1260, 1265, 1280, 1279, 1261, 15, 1256, 1240, 1241, 1306, 1251, 1307, 1259, 49,
  306, 558, 485, 544, 386, 1238, 407, 1060, 1326, 1281, 1236, 1157, 1102, 1077, 1026, 972,
  960, 1327, 1314, 1297, 1282, 1277, 1255, 1237, 1218, 1196, 1168, 1158, 1136, 1118, 1092,
  1078, 1059, 1042, 1032, 1027, 1007, 988, 977, 961, 945, 929, 915, 508, 1339, 1342, 1317,
  904, 100, 1083, 1325, 1309, 1292, 1310, 1311, 1328, 1312, 1298, 1289, 1288, 1285, 1293,
  1299, 1286, 1186, 1234, 1258, 1254, 1150, 1269, 1274, 574, 1165, 8, 1061, 1247, 168, 1245,
  702, 1246, 930,
];

export const TOKEN = 'wmohbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySUQiOjEsIm5hbWUiOiJTYWVpZCBWYWdoZWZpIiwiZXhwIjoyNTI0NjA4MDAwfQ.TA3DXrFuBkxbLWrO1u01bckS7HyP5JHoKnaEhY3Uxbk';
