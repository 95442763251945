import React from 'react';
import cnames from 'classnames';

import { ITableDb } from './types';

export const TableDb: ITableDb = ({
  contentList,
  title }) => {
  const id = title.replace(/[\s.]+/g, '-').toLowerCase();
  const tableId = id + '-table';
  // const isFirst = id.startsWith('a');
  return (
    <div
      className="accordion-item"
      id={ id }
    >
      <div className="accordion-header">
        <h3
          className={ 'accordion-button collapsed fs-4 m-0 col text-black' }
          role="button"
          data-bs-toggle="collapse"
          data-bs-target={ '#' + tableId }
        >
          { title }
        </h3>
      </div>
      <table
        className={ cnames(
          'table table-striped table-bordered table-hover table-responsive',
          'accordion-collapse collapse m-0')
          // isFirst ? 'show' : '')
        }
        data-bs-parent="#accordion"
        id={ tableId }
      >
        <thead>
          <tr>
            <th>File Name</th>
            <th>Year Published</th>
            <th>Official Number</th>
            <th>Permalink</th>
            <th>PDF URL Download</th>
            <th>Number of Pages</th>
          </tr>
        </thead>
        <tbody>
          { contentList.map((item, index) => (
            <tr key={ index.toString() + 'tr' }>
              <td>{ item.fileName }</td>
              <td>{ item.yearPublished }</td>
              <td>{ item.officialNumber }</td>
              <td>
                <a
                  href={ item.permalink }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                Link
                </a>
              </td>
              <td>
                <a
                  href={ item.pdfUrl }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                Download
                </a>
              </td>
              <td>{ item.numberOfPages }</td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  );
};
