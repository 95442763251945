/* eslint-disable max-len */
import React from 'react';

import { TableDb } from './TableDb';
import { DB_ITEMS } from './consts';

import './styles.scss';
export const Database: React.FC = () => {

  const renderDbTable = () =>
    DB_ITEMS.map((db, index) => (
      <TableDb
        key={ index.toString() + 'db' }
        title={ db.title }
        contentList={ db.contentList }
      />
    ));

  return (
    <div className="database chat-window mx-sm-auto mx-2 col-lg-10 fs-5">
      <h3 className='fw-bold'>Under Development askWMO Database (Last update 6 May 2024)</h3>
      { /* <nav className="menu w-100 mt-3">
        <ul className='d-flex justify-content-between px-1 mt-3'>
          { DB_ITEMS.map((table, index) => (
            <li key={ index.toString() + 'nav' }>
              <a
                className='fw-bold'
                href={ `#${table.title.replace(/\s+/g, '-').toLowerCase()}` }
              >
                { table.title.slice(0, 1) }
              </a>
            </li>
          )) }
        </ul>
      </nav> */ }
      <div
        className='accordion my-4'
        id="accordion"
      >
        {
          renderDbTable()
        }
      </div>

      <p className='my-4 more-db'>
        <p className='fw-bold mb-3'>To request the addition of new databases, please contact:</p>
        <p>Saeid A. Vaghefi</p>
        <p>AI and Climate Consultant (he/him, Dr.)</p>
        <p>World Meteorological Organization │ 7bis, avenue de la Paix, 1211 Geneva 2, Switzerland</p>
        <p>Email:<a href="mailto:svaghefi@wmo.int">svaghefi@wmo.int</a></p>
      </p>
    </div>
  );
};
