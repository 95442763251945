import React, { useState, useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Slider } from 'react-infinite-slider-carousel';

import { Sample } from './Sample';
import { questions } from './consts';
import { ISamples } from './types';

import './styles.scss';

export const Samples: ISamples = ({ onClick }) => {
  // State for window width to toggle between slider and grid view
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 576);

  // Update state when the window is resized
  useEffect(() => {
    const handleResize = () => setIsDesktop(window.innerWidth >= 576);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Memoize question items to avoid unnecessary re-renders
  const questionItems = useMemo(
    () =>
      questions.map(q => (
        <Sample
          key={ q }
          text={ q }
          onClick={ () => onClick(q) }
        />
      )),
    [onClick],
  );

  // Conditionally render grid or slider
  const samplesContent = useMemo(() => {
    if (isDesktop) {
      return (
        <div className="samples__container d-grid gap-3 fs-5">
          { questionItems }
        </div>
      );
    }

    return (
      <Slider
        config={ {
          infinite: true,
          centerMode: true,
          scaleUpOnActive: true,
          animationDuration: 500,
          minimumSwipeDistance: 100,
          slidesToMove: 1,
          visibleSlidesCount: 1,
          offset: 100,
          gapBetweenSlides: 20,
          slideClassName: 'slide',
        } }
      >
        { questionItems }
      </Slider>
    );
  }, [isDesktop, questionItems]);

  return (
    <div className="samples my-2">
      <h6 className="samples__header fw-bold fs-5 mb-2">Try asking</h6>
      { samplesContent }
    </div>
  );
};
